import React from "react";
import {graphql} from 'gatsby';
import Helmet from 'react-helmet';
import Layout from "../components/layout";
import TagList from "../components/tag-list";
import PageHeader from '../components/page-header.js';
import PostList from '../components/post-list.js';

export default class Blog extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        selectedTag : 'all'
      };
      this.updateBlogList = this.updateBlogList.bind(this);
    }

    updateBlogList(tag) {
      this.setState({
        selectedTag : tag
      });
    }

    render(){

      const page = this.props.data.wordpressPage;
      const posts = this.props.data.allWordpressPost.edges.slice().reverse() ;

      const filteredPosts = this.state.selectedTag === 'all' ? posts : posts.filter(({node}) => {
        if (node.tags === null){
          return false;
        } else {
          return node.tags.findIndex(i => i.slug === this.state.selectedTag) >= 0;
        }
      });


      return (
        <Layout>
          <Helmet title={page.title}></Helmet>
          <PageHeader pageTitle={page.title} />
          <TagList updateBlogList={this.updateBlogList} selectedTag={this.state.selectedTag}></TagList>
          <PostList posts={filteredPosts} />
        </Layout>
      )
    }
}

export const query  = graphql`
  query {
    wordpressPage (slug: {eq: "blog"}){
      title
    }
    allWordpressPost (filter:{ categories: {elemMatch:{slug:{eq: "travaux"}}} }, sort:{fields: [date]}) {
      edges {
        node {
          id
          title
          content
          date(formatString: "D MMMM YYYY" locale: "fr")
          excerpt
          slug
          acf {
            post_head {
              alt_text
              title
              localFile {
                name
                childImageSharp {
                  fluid (maxWidth: 700, maxHeight: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                    aspectRatio
                  }
                }
              }
            }
          }
          tags {
            slug
	    id
          }
        }
      }
    }
  }
`
